import React, { useEffect } from "react";
import LetsPlay from "./LetsPlay/Content.tsx";
import {
  WalletProvider,
  HippoWalletAdapter,
  AptosWalletAdapter,
  HippoExtensionWalletAdapter,
  MartianWalletAdapter,
  FewchaWalletAdapter,
  PontemWalletAdapter,
  SpikaWalletAdapter,
  RiseWalletAdapter,
  FletchWalletAdapter,
  TokenPocketWalletAdapter,
  ONTOWalletAdapter,
  BloctoWalletAdapter,
  SafePalWalletAdapter,
  WalletAdapterNetwork
} from '@manahippo/aptos-wallet-adapter';
const wallets = [
  new HippoWalletAdapter(),
  new MartianWalletAdapter(),
  new AptosWalletAdapter(),
  new FewchaWalletAdapter(),
  new HippoExtensionWalletAdapter(),
  new PontemWalletAdapter(),
  new SpikaWalletAdapter(),
  new RiseWalletAdapter(),
  new FletchWalletAdapter(),
  new TokenPocketWalletAdapter(),
  new ONTOWalletAdapter(),
  new BloctoWalletAdapter({network:WalletAdapterNetwork.Mainnet, bloctoAppId:'c1938457-aee7-41fb-89b4-520e6367686b' }),
  new SafePalWalletAdapter(),
];
function LetPlay() {
  return (
    <>
      <WalletProvider
      wallets={wallets}
      autoConnect={false} /** allow auto wallet connection or not **/
      onError={(error: Error) => {
        console.log('Handle Error Message', error);
      }}>
      {<LetsPlay></LetsPlay>}
    </WalletProvider>
    </>
  );
}

export default LetPlay;
