import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import './config';
import About from "./components/About"
import LetsPlay from "./components/LetsPlay"

ReactDOM.render(<LetsPlay />, document.getElementById("root"));
