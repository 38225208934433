import {useState, useEffect, useMemo, useRef, useCallback } from "react";
import React from 'react';
import "./content.css";
import logo from "../../images/footerLogo.png";
import loading from "../../images/website_loading.gif"
import buttonImage from "../../images/buttonImage.png"
import { Accordion, Container } from "react-bootstrap";
import bg from "../../images/marketBG.jpg";

import wallet1 from "../../images/wallet1.png";
import wallet2 from "../../images/wallet2.png";
import wallet3 from "../../images/wallet3.png";
import Unity, { UnityContext } from "react-unity-webgl";


import assert from "assert";
import { Express } from "express";


import LoadingBar from 'react-top-loading-bar'
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { AptosWalletName,BloctoWalletName,PontemWalletName,RiseWalletName, useWallet, MartianWalletName , NetworkInfo, WalletAdapterNetwork, AccountKeys, Wallet} from "@manahippo/aptos-wallet-adapter"



import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { child, get, getDatabase, push, ref, set, update } from "firebase/database";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

import {
  MeetingProvider,
  useMeeting,
  useParticipant,
  MeetingConsumer,
  createMicrophoneAudioTrack,
} from "@videosdk.live/react-sdk";
import {
  initMeeting
} from "@videosdk.live/js-sdk";
import { authToken, createMeeting  } from "../../api";
import ReactPlayer from 'react-player'

const firebaseConfig = {
  apiKey: "AIzaSyDtfLfJm2gSphWFE2d87KWygRmRR_KUNJw",
  authDomain: "metaagealpha.firebaseapp.com",
  projectId: "metaagealpha",
  storageBucket: "metaagealpha.appspot.com",
  messagingSenderId: "585045695954",
  appId: "1:585045695954:web:0f82032d423b3c80b6bc0b",
  measurementId: "G-GV360YRXHN",
  databaseURL: "https://metaagealpha-default-rtdb.firebaseio.com/",
};
const API_BASE_URL = process.env.REACT_APP_SERVER_URL;
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);let _mainWorldMeetingID = ""






var buildUrl = "./Test/Build/";
var loaderUrl = buildUrl + "/Test.loader.js";

const config_web3 = new UnityContext({
  loaderUrl: buildUrl + "Test.loader.js",
  dataUrl: buildUrl + "Test.data.unityweb",
  frameworkUrl: buildUrl + "Test.framework.js.unityweb",
  codeUrl: buildUrl + "Test.wasm.unityweb",
  
});
const config_guest = new UnityContext({
  loaderUrl: buildUrl + "Test.loader.js",
  dataUrl: buildUrl + "Test.data.unityweb",
  frameworkUrl: buildUrl + "Test.framework.js.unityweb",
  codeUrl: buildUrl + "Test.wasm.unityweb",
  
});
var script = document.createElement("script");
script.src = loaderUrl;















function Content() {
  
  const [progression, setProgression] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [guest,setGuest] = useState<boolean>(false);
  const [RPM_loaded,setRPMloaded] = useState<boolean>(true);
  const [open, setOpen] = React.useState(false);
  const { connect, disconnect, connected,signAndSubmitTransaction,account } = useWallet();
  //const [connected, setconnected] = useState(false);

  const [meetingId, setMeetingId] = useState(null);
  const [joined, setJoined] = useState(false);
  const [micEnabled, setMicEnabled] = useState(false);
  const [hideVoiceButtons, setHideVoiceButtons] = useState(false);
  let [customTrack, setCustomTrack] = useState();
  let customEchoTrack
  let meeting


  const getMeetingAndToken = async (id) => {
    
    const createdMeetingId =
      id == null ? await createMeeting({ token: authToken }) : id;
      //console.log(createdMeetingId)
      setMeetingId(createdMeetingId);
      //getTrack();
      const meetingIDdata = {
        meetingId : createdMeetingId
      };
      const dbRef = ref(getDatabase());
      const newMeetingIDKey = push(child(ref(database), 'meetingId')).key;
      const updates = {};
      updates['MeetingIDs/MainWorld'] = meetingIDdata;
      
      update(dbRef,updates)
     
      /*set(ref(database, 'MeetingIDs/MainWorld' ), {
        meetingId: createdMeetingId
      });*/
  };

  const getTrack = async () => {
    const track =  await createMicrophoneAudioTrack({
      encoderConfig: "high_quality",
      noiseConfig:{
        noiseSuppresion: true,
        echoCancellation: true,
        autoGainControl: true,
      }
    });
    setCustomTrack(track);
    //console.log(track)
  }
  

  


  async function joinVoiceChat()
{
  
  await getMeetingAndToken(meetingId);
  
}
function Voice() {
  
  const { join } = useMeeting();
  const { participants } = useMeeting();
  setJoined(true);
      join();
  
}
 
  function JoinScreen() {
    const [meetingId, setMeetingId] = useState(null);
    const onClick = async () => {
      await getMeetingAndToken(meetingId);
    };
    return (
      <div>
        <input
          type="text"
          placeholder="Enter Meeting Id"
          onChange={(e) => {
            setMeetingId(e.target.value);
          }}
        />
        <button onClick={onClick}>Join</button>
        {" or "}
        <button onClick={onClick}>Create Meeting</button>
      </div>
    );
  }
  function VoiceContainer(props) {
    
    const { join } = useMeeting();
    const { participants } = useMeeting();
    const joinMeeting = () => {
      setJoined(true);
      join();
    };
  
    return (
      <div className="container" style={{ marginTop: -150 ,  marginLeft: 320   }}>
        {joined && !hideVoiceButtons && (

        <div >
        <Controls />
        {[...participants.keys()].map((participantId) => (
          <VideoComponent participantId={participantId} />
        ))}
        </div>


        )}
              {!joined && !hideVoiceButtons &&(

        <button style={{backgroundColor: 'rgba(52, 52, 52, 0)', border:"none" }}>
                    
        <img style={{width: 70,height:70 }} src="./img/voiceButton.png" alt="Join Voice"  onClick={joinMeeting} />
        <h3 style={{marginTop:-58, fontSize:18, color:"#00BAFD"}} onClick={joinMeeting}>join</h3>
        <h3 style={{marginTop:-5, fontSize:18, color:"#00BAFD"}} onClick={joinMeeting}>voice</h3>

        </button>


      )}  

        
      </div>
    );
  }
  function Controls(props) {
    const { leave, toggleMic, toggleWebcam } = useMeeting();
    
    return (
      <div style={{ marginTop: 0 ,  marginLeft: 0   }}>
       
        
        <button style={{backgroundColor: 'rgba(52, 52, 52, 0)', border:"none" }}>
            
            <img style={{width: 70,height:70 }} src="./img/voiceButton.png" alt="Toggle Mic"  onClick={ ()=> { toggleMic();ToggleMic();}} />
            
            <img style={{width: 50,height:50,marginTop:0,marginLeft:-60,color:"#00BAFD" }} src="./img/Icon/Icon_Microphone.png" alt="Toggle Mic" onClick={ ()=> { toggleMic();ToggleMic();}}  />
            {!micEnabled &&(
               <img style={{width: 50,height:50,marginTop:0,marginLeft:-50,color:"#00BAFD" }} src="./img/Icon/Icon_Wrong.png" alt="Toggle Mic" onClick={ ()=> { toggleMic();ToggleMic();}}  />
            )}
           
            </button>

           
      </div>
    );
  }
  function VideoComponent(props) {
    const micRef = useRef(null);
    const { webcamStream, micStream, webcamOn, micOn ,isLocal } = useParticipant(
      props.participantId
    );
      
    const videoStream = useMemo(() => {
      if (webcamOn) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(webcamStream.track);
        return mediaStream;
      }
    }, [webcamStream, webcamOn]);
  
    useEffect(() => {
      if (micRef.current) {
        if (micOn) {
          const mediaStream = new MediaStream();
          mediaStream.addTrack(micStream.track);
          
         // mediaStream.addTrack(customEchoTrack);
          console.log(micStream.track)
  
          micRef.current.srcObject = mediaStream;
          micRef.current
            .play()
            .catch((error) =>
              console.error("videoElem.current.play() failed", error)
            );
        } else {
          micRef.current.srcObject = null;
        }
      }
    }, [micStream, micOn]);
  
    return (
      <div key={props.participantId}>
        {micOn && micRef && <audio ref={micRef}  muted={isLocal} />}
        {webcamOn && (
          <ReactPlayer
            //
            playsinline // very very imp prop
            pip={false}
            light={false}
            controls={true}
            muted={true}
            playing={true}
            //
            url={videoStream}
            //
            height={"100px"}
            width={"100px"}
            onError={(err) => {
              console.log(err, "participant video error");
            }}
          />
        )}
      </div>
    );
  }
  function ToggleMic() {
    
    if(micEnabled == true){
      setMicEnabled(false)
    }
    else{
      setMicEnabled(true)
    }
    
  }





  async function DownloadGame() {

    window.open('https://metaversebuild.s3.amazonaws.com/MetaAge_Metaverse.rar', '_blank', 'noreferrer');
  
    
  }

  async function ConnectWallet() {

    setOpen(true)
  
    
  }
  async function ChooseAndConnectWallet(provider:string) {
   
    getProvider(provider)
    try {
      /*const response = await wallet.connect();
      console.log(wallet)
    
      const account = await wallet.account();
      setAccount( account);
      setFromAccount(await client.getAccount(adminAccount.address())) */
      
  
      
    } catch (error) {
      // { code: 4001, message: "User rejected the request."}
    }

 }
 const getProvider = (provider:string) => {
  if (provider in window) {
    if(provider == "aptos"){
      connect(AptosWalletName)
    }
    else if(provider == "martian"){
      connect(MartianWalletName)
    }
    else if(provider == "rise"){
      connect(RiseWalletName)
    }
    else if(provider == "pontem"){
      connect(PontemWalletName)
    }
    
  }
  
};
  



  useEffect(function () {
    config_web3.on("loaded", function () {
      setIsLoaded(true);
      config_web3.setFullscreen(true);
    });
  }, []);

  useEffect(function () {
    config_web3.on("progress", function (progression) {
      setProgression(progression);
    });
  }, []);
  useEffect(function () {
    config_guest.on("loaded", function () {
      setIsLoaded(true);
      config_guest.setFullscreen(true);
    });
  }, []);

  useEffect(function () {
    config_guest.on("progress", function (progression) {
      setProgression(progression);
    });
  }, []);

  useEffect(function () {
    config_guest.on("HideVoiceButtons", async function () {
      
      
        setHideVoiceButtons(true)
        
      
      console.log("Voice buttons hidded")
      
    });
  }, []);

  useEffect(function () {
    config_guest.on("ShowVoiceButtons", async function () {
      
      
        setHideVoiceButtons(false)
        
      
      console.log("Voice buttons showed")
      
    });
  }, []);

async function playAsGuest()
{
 
  setGuest(true);
  
}
async function setFullscreen_web3() {
  config_web3.setFullscreen(true);
}
async function setFullscreen_guest() {
  config_guest.setFullscreen(true);
}

useEffect(function () {
  config_web3.on("WalletAddress", async function () {
   
  });
}, []);

useEffect(function () {
  config_web3.on("SubmitListing", async function () {
    
     
  });
}, []);

useEffect(function () {
  config_web3.on("PlayFingerGame", async function () {
    
    
    
  });
}, []);

useEffect(function () {
  config_web3.on("ClaimFingerGame", async function () {

    
    
   
    
  });
}, []);

useEffect(function () {
  config_web3.on("Maximize", async function () {
    config_web3.setFullscreen(true);
  });
}, []);

useEffect(function () {
  config_guest.on("Maximize", async function () {
    config_guest.setFullscreen(true);
  });
}, []);


useEffect(function () {
  config_web3.on("CreateMeeting", async function () {
    
    config_web3.send("GameManager", "GetMeetingID", meetingId?.toString() );
  });
}, []);
useEffect(function () {
  config_guest.on("CreateMeeting", async function () {

    

    joinVoiceChat()
    //config_guest.send("GameManager", "GetMeetingID",meetingId?.toString() );
    
    
    
    
    
  });
}, []);
useEffect(function () {
  config_guest.on("JoinMeeting", async function () {
    const dbRef = ref(getDatabase());
    console.log("joined meeting")
    
    get(child(dbRef, `MeetingIDs/MainWorld`)).then(async (snapshot) => {
      if (snapshot.exists()) {
        console.log(snapshot.val().meetingId);
        await getMeetingAndToken(snapshot.val().meetingId);
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });

  });
}, []);
useEffect(async function () {
   customEchoTrack = await createMicrophoneAudioTrack({
    encoderConfig: "high_quality_stereo",
    noiseConfig: {
      noiseSuppresion: true,
      echoCancellation: true,
      autoGainControl: true,
    },
  });
  
  setCustomTrack(customEchoTrack)
  console.log(customEchoTrack)
}, []);





  

  


      
  return (
    <>""
    {!connected && !guest && (
        <div style={{marginTop:-20}}>
        
        <div className="header" style={{ background: `url(${bg})` }}>
        <div className="header_content">
          <div className="logo">
            <img src={logo} className="img-fluid" alt="Metaage logo" />
          </div>
          <div className="text">
            <h3>Sign or Create Account</h3>
          </div>

          <div className="content">
            <div>
            <button onClick={playAsGuest}  style={{backgroundColor:"transparent", borderRadius:"10px",width:"21.3rem"}} >
            <div className="cards">
            
              <div className="cardTitle">
                <h3>Connect Wallet</h3>
              </div>
              <div className="cardImage">
                <img src={wallet1} className="img-fluid" alt="" />
              </div>
              <div className="cardText">
                <p>Connect your wallet to fully enjoy the MetaAge</p>
              </div>
              
              </div>
              </button>
            </div>


            <button onClick={playAsGuest} style={{backgroundColor:"transparent", borderRadius:"10px",width:"21.3rem"}} >
            <div className="cards">
              <div className="cardTitle">
                <h3>Play As Guest</h3>
              </div>
              <div className="cardImage">
                <img src={wallet2} className="img-fluid" alt="" />
              </div>
              <div className="cardText">
                <p>Register for unlimited and fast experience</p>
              </div>
            </div>
            </button>

            <button onClick={DownloadGame} style={{backgroundColor:"transparent", borderRadius:"10px",width:"21.3rem"}} >
            <div className="cards">
              <div className="cardTitle">
                <h3>Download Now for Windows</h3>
              </div>
              <div className="cardImage">
                <img src={wallet3} className="img-fluid" alt="" style={{width:"95%",height:"200%"}}/>
              </div>
              <div className="cardText">
                <p>Download for unlimited and fast experience</p>
              </div>
            </div>
            </button>
          </div>
        
          
           </div>
     
     
      </div>

      <div className="Accordion">
        <Container>
          <div className="title">
            <h3>The guide of MetaAge Metaverse;</h3>
          </div>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="headerContent">
                  <span>01</span>
                  What requiments do I need to play MetaAge?
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="bodyText">
                  You can log in from a PC or a MacBook running with Chrome or a
                  different browser.
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <div className="headerContent">
                  <span>02</span>
                  Can I log into my account from a different computer?
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="bodyText">
                  Yes, you can log in from any computer where you enter your
                  digital wallet.
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <div className="headerContent">
                  <span>03</span>
                  What is the Age?
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="bodyText">
                  $AGE is the exchangeable currency of MetaAge.
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <div className="headerContent">
                  <span>04</span>
                  What is the plot?
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="bodyText">
                  The plot is a non-fungible digital asset. MetaAge metaverse allows you to gain new
                  experiences, build your own structures, design your own game
                  with ready-made codes on it, and earn money from them.
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <div className="headerContent">
                  <span>05</span>
                  What are the main and mini games?
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="bodyText">
                  The main games are divided into p2e and non p2e. There is free
                  access to every game. The main games are usually in different
                  scenes and new ones are added every month. Unlike other games,
                  it offers an A quality gaming experience. Minigames are
                  socially themed. It is possible to come across it in every
                  corner or street of MetaAge. Mini and main games are played
                  online, and community members can create and add their own
                  games into the MetaAge. They can also make money from it.
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <div className="headerContent">
                  <span>06</span>
                  What are marketplace and game nfts?
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="bodyText">
                  <h5>Everything in one place </h5>
                  <p>
                    You can buy various items such as plot, costumes, real
                    estate, and game items in the MetaAge marketplace. MetaAge
                    provides fast and reliable digital art products that support
                    Sui blockchain infrastructure.{" "}
                  </p>
                  <h5>NFTs to boost your stand</h5>
                  <p>
                    Game NFTs are NFTs created for a number of main games that
                    are not for each game. You do not need these NFTs to enter
                    the game. These nfts provide you with a superior ability
                    such as power, speed,and balance in the game. Thanks to
                    NFTs, you can increase your advantage for P2O games and get
                    the chance to earn more money.
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </div>
      <Modal
                  open={open}
                  onClose={ConnectWallet}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{width:"20vw",color:"white",borderRadius:"2%",justifyContent:"center",textAlign:"center",backgroundColor:"white",alignContent:"center",position:"absolute",top:"40%",left:"40%",alignItems:"center"}}>
                   
                  <Button onClick={() =>connect(BloctoWalletName)} variant="contained" sx={{width:"15vw",marginTop:"1vw",fontFamily:"VT323",fontSize:"1vw",backgroundColor:"purple"}} >Blocto</Button>
                   <Button onClick={() =>connect(MartianWalletName)} variant="contained" sx={{width:"15vw",marginTop:"1vw",fontFamily:"VT323",fontSize:"1vw",backgroundColor:"purple"}} >Martian</Button>
                   <Button onClick={() =>connect(AptosWalletName)} variant="contained" sx={{width:"15vw",marginTop:"1vw",fontFamily:"VT323",fontSize:"1vw",backgroundColor:"purple"}} >Petra</Button>
                   <Button onClick={() =>connect(RiseWalletName)} variant="contained" sx={{width:"15vw",marginTop:"1vw",fontFamily:"VT323",fontSize:"1vw",marginBottom:"1vw",backgroundColor:"purple"}} >Rise</Button>
                   <Button onClick={() =>connect(PontemWalletName)} variant="contained" sx={{width:"15vw",marginTop:"0vw",fontFamily:"VT323",fontSize:"1vw",marginBottom:"1vw",backgroundColor:"purple"}} >Pontem</Button>
                   
                  </Box>
                </Modal>
        
        
        </div>
        
      ) } 

        {connected && (
          <div style ={{marginTop: -20 ,width:"100%",alignItems:"center"}}>

          <div style={{width:"100%",height:"100%" , display: isLoaded ? "flex" : "none", flexDirection:"column"}}>
          <Unity unityContext={config_web3} style={{width:"100%",height:"100%", marginBottom:0}} />
          <iframe id="frame" style={{width:"800px", height:"500px" , alignSelf:"center" , display:RPM_loaded ? "none" : "flex" , flexDirection:"column",marginTop:-600}} src="https://metaage.readyplayer.me/avatar?frameApi" allow="camera *; microphone *" ></iframe>
          {authToken && meetingId && (
              <div>
                <MeetingProvider
            config={{
              meetingId,
              micEnabled: true,
              webcamEnabled: false,
              name: "C.V. Raman",
              customMicrophoneAudioTrack: customEchoTrack,
              
            }}
            token={authToken}
            
          >
            <MeetingConsumer>
              {() => <VoiceContainer meetingId={meetingId} />}
            </MeetingConsumer>
          </MeetingProvider>
          
              </div>
            

          )}
            
            </div>
          
          <div style = {{ marginTop: -20 , width:"100%" , display: isLoaded ? "none" : "flex", flexDirection:"column"}}>
          <img src={loading} className="img-fluid" alt="Metaage logo"  style = {{ display: isLoaded ? "none" : "flex", flexDirection:"column"}}></img>
          <div style={{width:"100%", display:"flex", flexDirection:"row", alignItems:"center",marginTop:-60}}> 
          <LoadingBar color='#f11946' progress={progression * 100}       />
          <Box sx={{ width: '100%' }}>
          <LinearProgress variant="determinate" value={progression * 100} />
        </Box>
          
          </div>
          
          </div>
          
          
          
          
          

          
          
        </div>
          
        )}
        
        {guest && !connected && (
          <div style ={{marginTop: -20 ,width:"100%",alignItems:"center"}}>

            <div style={{width:"100%",height:"100%" , display: isLoaded ? "flex" : "none", flexDirection:"column"}}>
            <Unity unityContext={config_guest} style={{width:"100%",height:"100%", marginBottom:0}} />
            <iframe id="frame" style={{width:"800px", height:"500px" , alignSelf:"center" , display:RPM_loaded ? "none" : "flex" , flexDirection:"column",marginTop:-600}} src="https://metaage.readyplayer.me/avatar?frameApi" allow="camera *; microphone *" ></iframe>
            {authToken && meetingId && (
              <div>
                <MeetingProvider
            config={{
              meetingId,
              micEnabled: true,
              webcamEnabled: false,
              name: "C.V. Raman",
              customMicrophoneAudioTrack: customEchoTrack,
              
            }}
            token={authToken}
            
          >
            <MeetingConsumer>
              {() => <VoiceContainer meetingId={meetingId} />}
            </MeetingConsumer>
          </MeetingProvider>
          
              </div>
            

          )}
            
          
              
              </div>
            
            <div style = {{ marginTop: -20 , width:"100%" , display: isLoaded ? "none" : "flex", flexDirection:"column"}}>
            <img src={loading} className="img-fluid" alt="Metaage logo"  style = {{ display: isLoaded ? "none" : "flex", flexDirection:"column"}}></img>
            <div style={{width:"100%", display:"flex", flexDirection:"row", alignItems:"center",marginTop:-60}}>
              <LoadingBar color='#f11946' progress={progression * 100}       />
          <Box sx={{ width: '100%' }}>
          <LinearProgress variant="determinate" value={progression * 100} />
        </Box></div>
            
            </div>
            
            
            
            
            

            
            
          </div>
          
        )}
    </>
  );
}

export default Content;
